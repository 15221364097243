import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "community-modifiers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#community-modifiers",
        "aria-label": "community modifiers permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Community Modifiers`}</h1>
    <p>{`Due to bundle size and complexity constraints, we can't include all the possible
modifiers in the core library, but we do encourage everybody to publish their
own `}<strong parentName="p">{`custom modifiers`}</strong>{` in dedicated npm packages!`}</p>
    <p>{`When you do so, please make sure to add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`popperjs-modifier`}</code>{`
`}<a parentName="p" {...{
        "href": "https://docs.npmjs.com/files/package.json#keywords"
      }}>{`keyword`}</a>{` to your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`package.json`}</code>{`, so that other people can easily
discover the community modifiers `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/search?q=keywords%3Apopperjs-modifier"
      }}>{`on npm`}</a>{`.`}</p>
    <x-ad />
    <p>{`You can find all the information needed to learn how to create your very own
custom modifiers by visiting `}<a parentName="p" {...{
        "href": "../#custom-modifiers"
      }}>{`the dedicated docs`}</a>{`.`}</p>
    <p>{`Below you can find a few common examples to help get you started:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codesandbox.io/s/great-tesla-3roz7?file=/src/index.js"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`maxSize`}</code>{` modifier`}</a>{`: changes the size of your popper to fit
it within the available viewport space. (published as an `}<a parentName="li" {...{
          "href": "https://www.npmjs.com/package/popper-max-size-modifier"
        }}>{`npm
package`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codesandbox.io/s/bitter-sky-pe3z9?file=/src/index.js"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`sameWidth`}</code>{` modifier`}</a>{`: make your popper the same width as
the reference.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      